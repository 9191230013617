import React from "react";
import blogs from "../../blogData";
import BlogCard from "../blogs/BlogCard";
import { Link } from "react-router-dom";

const BlogList = () => {
    return (
        <div className="container mx-auto px-4 py-8">
            <h1 className="text-4xl font-bold mb-8 text-center">Blog List</h1>
            <div className="flex flex-wrap justify-around">
                {blogs.map((blog) => (
                    <Link key={blog.id} to={`/blog/${blog.id}`}>
                        <BlogCard blog={blog} />
                    </Link>
                ))}
            </div>
        </div>

    );
};

export default BlogList;
