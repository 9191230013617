import React from "react";
import NaVBar from "../../components/HomeNav";
import { Footer } from "../../components/HomeFooter";

import BlogListViewComponent from "../../components/blogs/BlogListViewComponent";

const BlogPage = () => {
  return (
    <>
      <head>
        <title>
          Kingston Downtown's Premier Dine-In Restaurant & Bar | Diverse Menu
          for Business Meetings, Parties, Date night, Takeaway, and More
        </title>
        <meta
          name="description"
          content="Explore Mercury Restaurant’s diverse menu in downtown Kingston, featuring family-friendly dishes, sports-themed specials, and options for large parties. Enjoy an array of gourmet selections crafted to delight every palate. Perfect for any occasion, from casual family dinners to lively group gatherings."
        />
      </head>
      <div className="content bg-[#333333] text-white">
        <NaVBar />
        <BlogListViewComponent />
        <Footer />
      </div>
    </>
  );
};

export default BlogPage;
