import React from "react";
import { useParams } from "react-router-dom";
import blogs from "../../blogData";
import BlogDetails from "../../components/blogs/BlogDetails";
import NaVBar from "../../components/HomeNav";
import { Footer } from "../../components/HomeFooter";

const BlogDetailPage = () => {
    const { id } = useParams();
    const blog = blogs.find((b) => b.id === parseInt(id));

    if (!blog) {
        return <div className="text-center">Blog not found</div>;
    }

    return (
        <>
            <NaVBar />
            <BlogDetails blog={blog} />;
            <Footer /> 
        </>
    )
};

export default BlogDetailPage;
