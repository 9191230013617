import React from "react";

const BlogDetails = ({ blog }) => {
    return (
        <div className="max-w-4xl mx-auto p-4">
            <img src={blog.heroImage} alt={blog.title} className="w-full h-96 object-cover rounded-lg" />
            <h1 className="text-4xl font-bold mt-6 mb-4">{blog.title}</h1>
            <p className="text-gray-500 mb-4">{blog.date}</p>
            <div className="prose max-w-none" dangerouslySetInnerHTML={{ __html: blog.content }}></div>
        </div>
    );
};

export default BlogDetails;
