const blogs = [
  {
    id: 1,
    title: "Discover Modern Asian Fusion at Mercury Bar & Lounge",
    date: "2024-12-21",
    heroImage: "/blog1.jpg",
    content:"<p>" +
                "Welcome to Mercury Bar & Lounge, where the vibrant flavors of Asia meet the charm of Kingston’s historic downtown. Nestled at 125 Brock Street, Mercury is more than just a restaurant—it's a culinary experience curated by the talented Chef Paulson George. With a menu inspired by his global culinary journey, this hotspot has quickly become a favorite for food enthusiasts in Kingston.  " +
            "</p> <br>" +
            "<p>" +
                "A Culinary Adventure Like No Other  " +
                "Chef Paulson George brings a unique flair to the kitchen, blending the rich traditions of Indian cuisine with the diverse flavors of China, Hong Kong, and Southeast Asia. The result is an extraordinary menu that redefines Asian fusion. Highlights include the Itaewon Beef Burger, infused with gochujang mayo and kimchi, and the Avocado Infused Hummus, topped with pistachio dukkah and pomegranate for a refreshing twist.  " +
            "</p> <br>" +
            "<p>" +
                "Looking for seafood? Try the Togarashi Sesame Crusted Tuna paired with soba noodles and Asian vinaigrette. Or indulge in the Coco-Licious Shrimp, featuring coconut and curry leaves. Every dish at Mercury tells a story of Chef Paulson’s culinary journey, combining familiar tastes with bold new creations.  " +
            "</p> <br>" +
            "<p>" +
                "A Space for Every Occasion  " +
                "Mercury Bar & Lounge is as versatile as it is delicious. The spacious venue is ideal for everything from intimate dinners to lively group celebrations or corporate events. Whether you're hosting a birthday party or catching a game with friends, the welcoming atmosphere, stylish decor, and efficient service will elevate your experience.  " +
            "</p> <br>" +
            "<p>" +
                "Sips to Match the Flavors  " +
                "The bar menu is just as enticing as the food. From expertly crafted cocktails like the Espresso Martini and Peach Bellini to a selection of local beers on tap, Mercury ensures your drink complements your meal perfectly. Whether you’re unwinding after work or raising a toast with loved ones, you’ll find the ideal sip here.  " +
            "</p> <br>" +
            "<p>" +
                "Make Your Visit Unforgettable  " +
                "Located in the heart of downtown Kingston, Mercury Bar & Lounge is easy to find, with paid parking conveniently located next door. Whether you’re a foodie, a cocktail lover, or just looking for a place with good vibes, Mercury promises an unforgettable experience.  " +
            "</p> <br>" +
            "<p>" +
                "Explore our menu and book your table today at <a href=\"https://www.themercury.ca\">themercury.ca</a>." +
            "</p> <br>" 
  },
  
];
export default blogs;





